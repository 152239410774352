@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
    font-family: "Proxima Nova";
    src: url("/fonts/Proxima Nova Reg.otf");
  }

  @font-face {
    font-family: "Proxima Nova";
    src: url("/fonts/Proxima Nova Reg It.otf");
    font-style: italic;
  }

  @font-face {
    font-family: "Proxima Nova";
    src: url("/fonts/Proxima Nova Light.otf");
    font-weight: 300;
  }

  @font-face {
    font-family: "Proxima Nova";
    src: url("/fonts/Proxima Nova Light It.otf");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "Proxima Nova";
    src: url("/fonts/Proxima Nova Sbold.otf");
    font-weight: 600;
  }

  @font-face {
    font-family: "Proxima Nova";
    src: url("/fonts/Proxima Nova Sbold It.otf");
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: "Proxima Nova";
    src: url("/fonts/Proxima Nova Bold.otf");
    font-weight: 700;
  }

  @font-face {
    font-family: "Proxima Nova";
    src: url("/fonts/Proxima Nova Bold It.otf");
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/merriweather/v30/u-440qyriQwlOrhSvowK_l5-fCZMdeX3rg.woff2) format('woff2');
  }
}
html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
